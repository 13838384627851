.AddNewMessage {
  // border: 1px red solid;
  margin-top: 30px;
  margin-bottom: 30px;

  .addNew-message-paper {
    padding: 10px 20px 30px 20px;
    height: 100%;
    background-color: transparent;
    .gridContainer {
      // border: 1px solid red;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0;
      margin-top: 20px;

      .gridContainer-first-block {
        // border: 1px solid red;
      }

      .gridContainer-second-block {
        margin-top: 20px;
        border-top: 2px solid #07649a91; /* Ajouter une bordure seulement en haut */
        // box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.5); /* Ajoute une ombre à la bordure supérieure */
      }

      .gridItem {
        // border: 1px solid rgb(204, 13, 147);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        margin: 20px 0;

        .gridItem-formControl {
          // border: 1px solid rgb(0, 21, 255);
          width: 100%;
          margin: 0;

          .sliderLabel {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .sliderInput {
              display: flex;
            }
          }
          .orderBy {
            display: flex;

            .orderBy-control {
              margin-left: 20px;
            }
          }
        }
      }

      .gridItem-composite {
        // border: 1px solid red;

        .gridItem-formControl-composite {
          // border: 1px solid rgb(0, 21, 255);
          padding: 0;
          margin: 0;

          .gridItem-formControlLabel {
            // border: 1px solid rgb(81, 255, 0);
            margin-left: 0;
            margin-right: 0;

            display: flex;
            align-items: center;

            &.left {
              margin-left: -12px;
            }

            .frequency-label {
              margin-right: 20px;
            }
          }
        }
      }
    }
    .gridError {
      margin: 0;
      padding: 0;
    }

    hr {
      height: 1.5px;
      width: 100%;
      background-color: #07659a;
      opacity: 0.5;
      border: none;
      margin: 20px auto 10px auto;
      // transition: all 0.7s ease-in-out;
      transition: opacity 0.7s ease-in-out, width 0.7s ease-in-out;
      // Transition douce pour l'opacité et la largeur
      &:hover {
        opacity: 0.2;
        width: 80%;
      }
    }

    .footer-addNew-message {
      // border: 1px red solid;
      margin: 40px 0px 20px 0px;
      width: 100%;
      display: flex;
      justify-content: space-around;
    }
  }
}

.file-card {
  // border: 2px red solid;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .btn-approbation{
    margin-bottom: 20px;
  }

  .titre-image {
    // border: 1px solid orange;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      text-overflow: initial;
      white-space: normal;
    }
  }

  .file-inputs {
    border: 3px dashed rgb(122, 162, 189);
    width: auto;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 20px;

    input {
      display: none;
    }

    .imageToUpload {
      display: flex;
      justify-content: center;
      align-items: center;
      // object-fit: contain;
      max-width: 100%; /* Limite la largeur de l'image au conteneur */
      max-height: 100%; /* Limite la hauteur de l'image au conteneur */

      img {
        max-width: 100%; /* Limite la largeur de l'image au conteneur */
        max-height: 100%; /* Limite la hauteur de l'image au conteneur */
      }
    }
  }

  .file-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    padding: 0;

    .name-and-icon {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 14px;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.Mui-evenRow {
  background-color: #ebd8ed;
}
.Mui-oddRow {
  background-color: #afc6d7;
}

.dataGridBox {
  // border: 1px solid red;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 30px;
}

.grid-customToolbar-container {
  display: flex;

  justify-content: space-between;
  margin: 10px 0 10px 10px;
}

/* Classe pour personnaliser l'en-tête */
.MuiDataGrid-columnHeaders {
    background-color: rgba(205, 221, 228, 0.39);
}

/* Classe pour personnaliser le pied de page */
.MuiDataGrid-footerContainer {
    background-color: rgba(200, 210, 214, 0.39);
}
.btn-supprimer {
  margin: 20px;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: bolder !important;
}

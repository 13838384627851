.App {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100vh;
  background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(92, 23, 144, 0.3)),
    url("../src/assets/images/image1.png");
  background-size: cover;
  background-position: center;
}

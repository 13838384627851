.header-mainContent {
  box-shadow: 1px 1px 5px 0px rgb(0 0 0 / 40%);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  // height: 14vh;

  @media (max-width: 820px) {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 5px;
  }

  .city-logo {
    grid-column: 1 / span 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;

    img {
      padding: 5px;
      max-width: 160px;
      max-height: 12vh;
      object-fit: cover;

      @media (max-width: 820px) {
        padding: 10px 0 5px 0;
      }
    }
  }

  .header-middle {
    grid-column: 2 / span 1;
    padding: 10px 0;

    @media (max-width: 820px) {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: left;
      // padding-left: 10vw;
      padding: 5px 0 5px 10vw;
      box-sizing: border-box;
    }

    .cityName {
      font-size: 1.2rem;
      font-weight: bold;
      color: #07659a;
    }

    .ville {
      font-size: 0.8rem;
      padding-bottom: 5px;
    }

    .mrc {
      font-size: small;
    }

    .fuseau {
      font-size: small;
    }
  }

  .header-right {
    grid-column: 3 / span 1;
    padding: 10px 0;

    @media (max-width: 820px) {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: left;
      // padding-left: 10vw;
      padding: 5px 0 10px 10vw;
      box-sizing: border-box;
    }

    .version {
      font-size: medium;
      font-weight: bold;
      padding-bottom: 5px;
    }

    .wrapTextAlert {
      display: flex;
      align-items: center;

      margin-right: 10px;

      .text {
        font-size: small;
      }

      .icon {
        // display: flex;
        margin-left: 10px;
      }
    }
  }
}

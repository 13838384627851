.profile {
  // border: 2px solid rgb(52, 177, 150);
  width: 95%;
  margin-bottom: 30px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 30px;

  .topPage {
    box-shadow: 1px 1px 5px 0px rgb(0, 0, 0, 0.4);
    padding: 10px;
    font-size: 1rem;
    border-radius: 2px;
    font-weight: 400;
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: flex-start;
      gap: 20px;
    }

    .topTitle {
      display: flex;
      justify-content: left;
      align-items: center;

      div {
        padding: 5px 5px 0 10px;
      }

      span {
        font-weight: 800;
        padding: 5px 5px 0 5px;
      }
    }

    .topNav {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      gap: 30px;

      @media (max-width: 1040px) {
        flex-direction: row-reverse;
        justify-content: space-between;
      }

      .topNav-close {
        display: flex;
        justify-content: center;
        color: #000000;

        &:hover {
          color: #07659a;
        }

        &:active {
          color: darken(#07659a, 10%);
        }
      }
    }
  }

  .profile-paper {
    // border: 2px solid rgb(52, 177, 150);
    padding: 20px;
    height: 100%;
    background-color: transparent;

    .profile-form {
      //   border: 1px solid rgb(180, 23, 242);
      display: flex;
      flex-direction: column;
      padding: 20px;
      overflow: auto;
      overflow-x: hidden;
      justify-content: space-between;
      align-items: center;

      & input::selection {
        background-color: transparent;
      }

      .gridContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0;

        .gridItem {
          // border: 1px solid red;
          display: flex;
          flex-direction: column;
          margin: 0;
          padding: 20px;
          padding-left: 20px;
          margin-bottom: 30px;

          .gridItem-formControl {
            // border: 1px solid rgb(0, 21, 255);
            margin: 0;
            width: 100%;
          }

          .checkboxItem-title {
            // border: 1px solid rgb(0, 255, 55);
            font-weight: 500;
            color: #07659a;
            margin-bottom: 20px;
          }

          .checkboxItem-selectAll {
            // border: 1px solid rgb(4, 0, 255);
            margin-bottom: 20px;
            color: grey;
          }

          &.checkboxItem {
            // border: 1px solid red;
            margin-top: 0;
            margin-bottom: 0;
            padding-top: 0;
            padding-bottom: 0;
          }
        }

        .btnGenerer {
          // border: 1px solid red;
          display: flex;
          // flex-direction: row;
          // justify-content: right;
        }

        .gridContainer-phonePost {
          // border: 1px solid red;
          display: flex;
          padding: 0;
          margin: 0;
        }

        hr {
          height: 2px;
          width: 70%;
          background-color: #07659a;
          opacity: 0.5;
          border: none;
          margin: 0 auto 20px auto;
          transition: all 0.7s ease-in-out;

          &:hover {
            opacity: 0.2;
            width: 60%;
          }
        }
      }
    }
  }
}

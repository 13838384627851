.ForgotPW {
  form {
    background-color: white;
    padding: 0px 60px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .forgotHeader {
      margin: 30px 0 20px 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .forgotHeader__logo {
        display: flex;
        width: 80px;
        height: auto;

        img {
          width: 100%;
        }
      }
      .titles {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 20px;

        .forgotHeader__title {
          color: #07659a;
          text-align: center;
          font-size: 2rem;
        }

        .forgotHeder__second {
          display: flex;
          justify-content: space-between;
          width: 254px;

          .forgotHeader__subtitle {
            color: #364148;
            text-align: center;
            font-size: 1rem;
          }

          .backIcon {
            cursor: pointer;
            color: #07659a;
            /* Ajouter une transition de 0.2 seconde avec un effet "ease" pour l'animation */
            transition: transform 0.2s ease;

            &:hover {
              color: #0380c9d5;
              /* Déplacer l'icône de 5 pixels vers la droite au survol */
              transform: translateX(-3px);
            }
          }
        }
      }
    }

    .inputPW {
      margin-bottom: 10px;
    }

    button {
      width: 100%;
      height: 40px;
      padding: 10px;
      border-radius: 5px;
      border: none;
      background-color: #07659a;
      color: white;
      margin: 20px;
      font-size: large;
      font-weight: bold;
      cursor: pointer;

      &:hover {
        background-color: #0380c9d5;
      }

      &.nope {
        &:hover {
          animation: nope 0.4s forwards;
        }

        @keyframes nope {
          0% {
            transform: translateX(0px);
          }
          20% {
            transform: translateX(-8px);
          }
          40% {
            transform: translateX(8px);
          }
          60% {
            transform: translateX(-8px);
          }
          80% {
            transform: translateX(8px);
          }
          100% {
            transform: translateX(0px);
          }
        }
      }
    }

    .errorMessage {
      display: flex;
      margin-bottom: 20px;
      width: 100%;
      justify-content: center;
      color: red;
      font-weight: 500;
    }
  }
}

.Signin {
  form {
    background-color: white;
    padding: 0px 60px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .loginHeader {
      margin: 30px 0 20px 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .loginHeader__logo {
        display: flex;
        width: 80px;
        height: auto;

        img {
          width: 100%;
        }
      }

      .loginHeader__title {
        color: #07659a;
        text-align: center;
        font-size: 2rem;
        margin: 20px;
      }
    }

    .inputPW{
      margin-bottom: 10px;
    }

    button {
      width: 100%;
      height: 40px;
      padding: 10px;
      border-radius: 5px;
      border: none;
      background-color: #07659a;
      color: white;
      margin: 20px;
      font-size: large;
      font-weight: bold;
      cursor: pointer;

      &:hover {
        background-color: #0380c9d5;
      }

      &.nope {
        &:hover {
          animation: nope 0.4s forwards;
        }

        @keyframes nope {
          0% {
            transform: translateX(0px);
          }
          20% {
            transform: translateX(-8px);
          }
          40% {
            transform: translateX(8px);
          }
          60% {
            transform: translateX(-8px);
          }
          80% {
            transform: translateX(8px);
          }
          100% {
            transform: translateX(0px);
          }
        }
      }
    }

    .loginFooter { 
      display: flex;
      margin-bottom: 20px;
      width: 100%;
      justify-content: right;

      a {
        color: #677983;
        text-decoration: none;
        font-size:medium;
        font-weight: bold;

        &:hover {
          color: #07659a;
        }
      }
    }
  }
}

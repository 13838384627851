.container {
  width: 100%;
  //   margin-bottom: 20px;
  margin: 30px 20px;

  .MuiAccordion-root {
    // border: 2px solid red;

    .MuiAccordionSummary-root {
      //   border: 2px solid red;

      .MuiAccordionSummary-content {
        // border: 2px solid red;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .MuiAccordionSummary-content-Municipality {
          //   border: 2px solid red;
          display: flex;
          width: 100%;
          align-items: center;

          .Muni-logo {
            width: 32px; /* ajustez la largeur selon vos besoins */
            height: 32px; /* ajustez la hauteur selon vos besoins */
            border-radius: 50%;
            margin: 0 10px;
          }

          //   .MuiTypography-root {
          //     //   border: 1px solid rgb(17, 0, 255);
          //   }
        }

        // .ButtonRetirer {
        //     // border: 2px solid rgb(33, 138, 41);
        // }
      }
    }

    .MuiAccordionDetails-root {
      // border: 2px solid red;
      .MuiTypography-nameProfile {
        font-weight: 500;
      }

      // .MuiTypography-services {

      // }
    }
  }
}

.AccordionDetails-container {
  // border: 1px solid rgb(255, 0, 221);
  display: flex;
  justify-content: space-between;
  padding-right: 5px !important;

  .AccordionDetails-text {
    // border: 1px solid red;
  }

  .edit-profilAndServices-btn {
    // border: 1px solid rgb(28, 22, 110);
    height: 40px !important;
    width: 40px !important;
    margin-top: 5px;
    // .edit-profilAndServices-icon {
    //   border: 1px solid rgb(67, 209, 32);
    // }
  }
}

.delete-profilAndServices-icon {
  color: #a76c6c;

  &:hover {
    color: lighten($color: #ad2222, $amount: 20%);
  }
}

.edit-profilAndServices-icon {
  color: #347a50;

  &:hover {
    color: lighten($color: #305d42, $amount: 20%);
  }
}

.main-wrapper {
  width: 100%;
  margin-bottom: 30px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  // border: solid 2px red;

  .main-container-MuImages {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .topPage {
      box-shadow: 1px 1px 5px 0px rgb(0, 0, 0, 0.4);
      padding: 10px;
      font-size: 1rem;
      border-radius: 2px;
      font-weight: 400;
      display: flex;
      justify-content: space-between;

      @media (max-width: 1040px) {
        flex-direction: column;
        justify-content: flex-start;
        gap: 20px;
      }

      .topTitle {
        display: flex;
        justify-content: left;
        align-items: center;

        div {
          padding: 5px 5px 0 10px;
        }

        span {
          font-weight: 800;
          padding: 5px 5px 0 5px;
        }
      }

      .topNav {
        // border: 1px solid red;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        gap: 30px;

        @media (max-width: 1040px) {
          flex-direction: row-reverse;
          justify-content: space-between;
        }
        .topNav-close {
          display: flex;
          color: #000000;

          &:hover {
            color: #07659a;
          }

          &:active {
            color: darken(#07659a, 10%);
          }
        }
      }
    }

    .cards-container {
      display: flex;
      justify-content: space-between;
      margin: 0;
      padding: 0;
      width: 100%;
      // border: solid 2px rgb(0, 255, 85);

      .card-item {
        // border: solid 2px red;
        margin: 0;
        padding: 0;

        &.first-item {
          padding-right: 20px;
        }

        &.second-item {
          padding-left: 20px;
        }
      }


      @media (max-width: 899px) {
        margin-bottom: 30px;
      }

      //   margin-left: 20; /* Supprime la marge de droite pour le dernier div */
    }
  }
}

.editOneMessageOfAll {
  // border: 2px solid rgb(52, 177, 150);
  width: 95%;
  margin-bottom: 30px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 30px;

  .topPage {
    box-shadow: 1px 1px 5px 0px rgb(0, 0, 0, 0.4);
    padding: 10px;
    font-size: 1rem;
    border-radius: 2px;
    font-weight: 400;
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: flex-start;
      gap: 20px;
    }

    .topTitle {
      display: flex;
      justify-content: left;
      align-items: center;

      div {
        padding: 5px 5px 0 10px;
      }

      span {
        font-weight: 800;
        padding: 5px 5px 0 5px;
      }
    }

    .topNav {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      gap: 30px;

      @media (max-width: 1040px) {
        flex-direction: row-reverse;
        justify-content: space-between;
      }

      .topNav-close {
        display: flex;
        justify-content: center;
        color: #000000;

        &:hover {
          color: #07659a;
        }

        &:active {
          color: darken(#07659a, 10%);
        }
      }
    }
  }

  .editOneMessageOfAll-paper {
    // border: 2px solid rgb(52, 177, 150);
    padding: 20px;
    height: 100%;
    background-color: transparent;

    .editOneMessageOfAll-form {
      // border: 1px solid rgb(180, 23, 242);
      display: flex;
      flex-direction: column;
      // padding: 20px;
      // overflow: auto;
      // overflow-x: hidden;
      justify-content: space-between;
      align-items: center;

      .gridContainer {
        // border: 1px solid red;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0;
        margin-top: 20px;

        .gridItem {
          // border: 1px solid rgb(204, 13, 147);
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 0 20px;
          margin: 20px 0;

          .gridItem-formControl {
            // border: 1px solid rgb(0, 21, 255);
            width: 100%;
            margin: 0;

            .sliderLabel {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .sliderInput {
                display: flex;
              }
            }
            .orderBy {
              display: flex;

              .orderBy-control {
                margin-left: 20px;
              }
            }
          }
        }

        .gridItem-composite {
          // border: 1px solid red;

          .gridItem-formControl-composite {
            // border: 1px solid rgb(0, 21, 255);
            padding: 0;
            margin: 0;

            .gridItem-formControlLabel {
              // border: 1px solid rgb(81, 255, 0);
              margin-left: 0;
              margin-right: 0;

              display: flex;
              align-items: center;

              &.left {
                margin-left: -12px;
              }

              .frequency-label {
                margin-right: 20px;
              }
            }
          }
        }
      }
      .gridError {
        margin: 0;
        padding: 0;
      }

      hr {
        height: 2px;
        width: 85%;
        background-color: #07659a;
        opacity: 0.5;
        border: none;
        margin: 20px auto 20px auto;
        transition: all 0.7s ease-in-out;

        &:hover {
          opacity: 0.2;
          width: 60%;
        }
      }

      .footer-Edit-OneMessageOfAll {
        // border: 1px red solid;
        margin: 40px 0px 20px 0px;
        width: 100%;
        display: flex;
        justify-content: space-around;
      }
    }
  }
}

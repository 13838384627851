.searchBar {
  width: 200px;
  margin: 20px 0 20px 0;
  display: flex;
  align-items: center;
  border-bottom: 0.5px solid lightgray;
  padding: 3px;

  .MuiSvgIcon-root {
    cursor: pointer;
  }

  input {
    border: none;
    outline: none;
    background: transparent;
    width: 18rem;

    &::placeholder {
      font-size: 12px;
    }
  }
}

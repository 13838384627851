.muStatistiques {
  // border: 3px red dashed;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  top: 30px;
  width: 95%;

  padding-bottom: 30px;

  .statistiques-main-container {
    // border: 2px solid rgb(52, 177, 150);
    display: flex;
    flex-direction: column;
    gap: 20px;

    .topPage {
      // border: 2px solid rgb(177, 52, 129);
      box-shadow: 1px 1px 5px 0px rgb(0, 0, 0, 0.4);
      padding: 10px;
      font-size: 1rem;
      border-radius: 2px;
      font-weight: 400;
      display: flex;
      justify-content: space-between;

      @media (max-width: 1040px) {
        flex-direction: column;
        justify-content: flex-start;
        gap: 20px;
      }

      .topTitle {
        display: flex;
        justify-content: left;
        align-items: center;

        div {
          padding: 5px 5px 0 10px;
        }

        span {
          font-weight: 800;
          padding: 5px 5px 0 5px;
        }
      }

      .topNav {
        // border: 1px solid rgb(0, 255, 55);
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        gap: 30px;

        @media (max-width: 1040px) {
          flex-direction: row-reverse;
          justify-content: space-between;
        }

        .topMiddle {
          // border: red 1px solid;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 30px;

          @media (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;;
            gap: 10px;
          }

          .btnGroupVersion{
            margin: 0;
          }
          .btnVersion {
            height: 30px;
            font-size: 12px;
            padding-top: 0;
            padding-bottom: 0;
          }
        }

        .topNav-close {
          color: #000000;

          &:hover {
            color: #07659a;
          }

          &:active {
            color: darken(#07659a, 10%);
          }
        }
      }
    }

    .statsBox {
      // border: 1px red solid;
      background-color: transparent;

      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 30px;

      .paper {
        // border: 1px rgb(170, 58, 140) solid;
        background: transparent;
        display: flex;
        // margin-bottom: 30px;
        .spanningTable {
          // border: 1px rgb(170, 125, 58) solid;
          width: 100%;
          background-color: transparent;
        }
      }
    }
  }
}

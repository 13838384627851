.Mui-evenRow {
  background-color: #ebd8ed;
}
.Mui-oddRow {
  background-color: #afc6d7;
}

.dataGridBox {
  // border: 1px solid red;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.grid-customToolbar-container {
  display: flex;

  justify-content: space-between;
  margin: 10px 0 10px 10px;
}

.btn-supprimer {
  margin: 20px;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: bolder !important;
}

.cellContent {
  max-width: 250px; /* largeur maximale pour la cellule */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cellContent:hover {
  white-space: normal;
  overflow: visible;
}

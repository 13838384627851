.MiseEnLigneAddNewForm {
  // border: 2px solid rgb(177, 52, 52);
  width: 100%;
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;


  .gridContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 !important;

    .gridItem {
      // border: 1px solid red;
      display: flex;
      flex-direction: column;
      // margin: 0 !important;
      padding: 20px;
      padding-left: 20px;
      margin-bottom: 20px;

      .gridItem-formControl {
        // border: 1px solid rgb(0, 21, 255);
        margin: 0;
        width: 100%;
      }

      .renewIcon {
        transition: transform 0.5s ease-in-out !important;
        // Ajoute une transition pour une animation plus fluide
      }

      .renewIcon:hover {
        transform: rotate( 180deg ) !important;
        // Rotation de 180 degrés lorsqu'il est survolé
      }
    }
  }

  .titre {
    // border: 1px solid orange;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      text-overflow: initial;
      white-space: normal;
    }
  }
}
// .gridContainer-phonePost {
//   // border: 1px solid red;
//   display: flex;
//   padding: 0;
//   margin: 0;
// }
.muAddUser {
//   border: 2px solid rgb(52, 177, 150);
  margin-bottom: 30px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .muAddUser-paper {
    // border: 2px solid rgb(52, 177, 150);
    padding: 20px;
    height: 100%;
    background-color: transparent;

    .muAddUserForm {
    //   border: 1px solid rgb(180, 23, 242);
      display: flex;
      flex-direction: column;
    //   overflow: auto;
    //   overflow-x: hidden;
      justify-content: space-between;
      align-items: center;

      hr {
        height: 2px;
        width: 85%;
        background-color: #07659a;
        opacity: 0.5;
        border: none;
        margin: 20px auto 20px auto;
        transition: all 0.7s ease-in-out;

        &:hover {
          opacity: 0.2;
          width: 60%;
        }
      }

      .footerAddUser {
        width: 100%;
        display: flex;
        justify-content: space-around;
        margin: 10px;
      }
    }
  }
}

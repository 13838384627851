.CompactCard {
  display: flex;
  flex-direction: column;
  max-height: 100px;
  border-radius: 5px;
  padding: 10px;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(195, 193, 193, 0.47);
  cursor: pointer;
  gap: 10px;

  .separator {
    position: relative;
  }

  .separator::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 1px solid rgb(144, 151, 156);
  }

  .box-title {
    color: #252525;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 5px;

    .leftDetail {
      flex: 1;
      display: flex;
      justify-content: left;
      gap: 10px;
      .png {
        display: flex;
        align-items: center;
        color: #252525;
        font-size: 24px;
      }

      span {
        font-weight: bold;
        font-size: 20px;
      }
    }

    .rightDetail {
      flex: 1;
      display: flex;
      justify-content: flex-end;

      .MuiSvgIcon-root {
        transition: font-size 0.3s, color 0.3s;
      }

      &:hover {
        .MuiSvgIcon-root {
          color: rgb(124, 155, 175);
          font-size: 28px;
        }
      }
    }
  }

  .box-detail {
    // border: 1px red solid;
    display: flex;
    justify-content: space-around;

    .box-detail-info {
      display: flex;
      flex-direction: column;
      // align-items: center;
      span {
        color: #252525;
        font-weight: bold;
        font-size: 12px;

        + div {
          color: #252525;
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
  }

  &:hover {
    box-shadow: 2px 4px 10px 1px rgba(26, 25, 25, 0.47);
  }

  &.last-card {
    cursor: auto;
  }
}

.ExpandedCard-container {
  // border: 2px rgb(0, 255, 0) solid;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100%);
  // height: auto;

  .ExpandedCard {
    // border: 2px red solid;
    // position: relative;
    // width: 100%;
    // height: auto;

    display: flex;
    flex-direction: column;
    gap: 5px;

    .expCardTop {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;

      .expCardTitle {
        font-size: 1.2em;
        font-weight: bold;
      }
      .zoomOut {
        display: flex;
        &:hover {
          color: rgb(11, 12, 13);
        }
      }
    }

    .chartContainer {
      align-self: center;
      width: min(90%, 800px);
    }
  }
}

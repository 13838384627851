.cartographie {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 95%;
  position: relative;
  top: 30px;
  height: calc(100% - 60px);
  //   border: 1px solid red;

  .cartographie-main-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: calc(100% - 120px);
    // border: 2px solid rgb(52, 177, 150);
    //   @media (max-width: 1040px) {
    //
    //   }

    .topPage {
      box-shadow: 1px 1px 5px 0px rgb(0, 0, 0, 0.4);
      padding: 10px;
      font-size: 1rem;
      border-radius: 2px;
      font-weight: 400;

      display: flex;
      justify-content: space-between;

      //   @media (max-width: 1040px) {
      //     flex-direction: column;
      //     justify-content: flex-start;
      //     gap: 20px;
      //   }

      .topTitle {
        display: flex;
        justify-content: left;
        align-items: center;

        div {
          padding: 5px 5px 0 10px;
        }

        span {
          font-weight: 800;
          padding: 5px 5px 0 5px;
        }
      }

      .topNav {
        // border: 1px solid red;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        width: 150px;

        @media (max-width: 1040px) {
          flex-direction: row-reverse;
          justify-content: space-between;
        }

        .topNav-close {
          color: #000000;

          &:hover {
            color: #07659a;
          }

          &:active {
            color: darken(#07659a, 10%);
          }
        }
      }
    }

    .cartographie-paper {
      //   border: 2px solid rgb(52, 177, 150);
      height: calc(100% - 80px);
      overflow: auto;
      background-color: transparent;
      display: flex;
      flex-direction: column;
      padding: 15px;
      line-height: 2;

      p {
        padding-bottom: 20px;
      }

      ul {
        padding-bottom: 10px;
      }
    }
  }
}

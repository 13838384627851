.LandingPage {
  width: 95%;
  height: 100%;
  margin-bottom: 30px;
  margin-top: 30px;
  gap: 20px;

  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
  overflow: auto;

  .main-container {
    width: auto;
    // height: 50px;
    // border: 1px red solid;
    overflow-y: scroll;
    scrollbar-width: thin;
    margin-top: -20px;

    // &::-webkit-scrollbar {
    //   display: none;
    // }

  }
}

.MuMessages {
  // border: 1px solid red;
  display: flex;
  flex-direction: column;
  width: 95%;
  height: 90vh;
  position: relative;
  top: 30px;

  .topPage {
    // border: 1px solid red;
    box-shadow: 1px 1px 5px 0px rgb(0, 0, 0, 0.4);
    padding: 10px;
    font-size: 1rem;
    border-radius: 2px;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 20px;

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: flex-start;
      gap: 20px;
    }

    .topTitle {
      display: flex;
      justify-content: left;
      align-items: center;

      div {
        padding: 5px 5px 0 10px;
      }

      span {
        font-weight: 800;
        padding: 5px 5px 0 5px;
      }
    }
  }

  .MuiTabPanel-root{
    padding: 0;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.MuListe {
  display: flex;
  flex-direction: column;
  width: 95%;
  position: relative;
  top: 30px;
  height: calc(100% - 60px);

  // border: 1px solid rgb(177, 108, 52);
  .topPage {
    box-shadow: 1px 1px 5px 0px rgb(0, 0, 0, 0.4);
    padding: 10px;
    font-size: 1rem;
    border-radius: 2px;
    font-weight: 400;

    display: flex;
    justify-content: space-between;

    .topTitle {
      display: flex;
      justify-content: left;
      align-items: center;

      div {
        padding: 5px 5px 0 10px;
      }

      span {
        font-weight: 800;
        padding: 5px 5px 0 5px;
      }
    }

    .topNav {
      // border: 1px solid red;
      display: flex;
      flex-direction: row-reverse;
      justify-content: left;
      align-items: center;
      gap: 40px;

      .topNav-close {
        display: flex;
        justify-content: center;
        color: #000000;

        &:hover {
          color: #07659a;
        }

        &:active {
          color: darken(#07659a, 10%);
        }
      }
    }
  }

  .dataGridBox {
    // border: 1px solid rgb(52, 177, 150);
    height: calc(100% - 120px);
    // .MuiDataGrid-main {
      // height: 58vh;
    // }
  }
}

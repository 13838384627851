.header-row,
.total-row,
.average-row {
  background-color: #abb4e673;
  // background-color: rgb(224, 224, 233, 0.8);
  // background-color: rgba(83, 125, 177, 0.8);
  // background-color: rgba(107, 129, 156, 0.8);
  z-index: 10;
}

.sticky-row{
    position: sticky;
    top: 0;
}


.WarningMessages {
  width: 100%;
  margin-bottom: 30px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  //   border: solid 2px red;

  .topPage {
    box-shadow: 1px 1px 5px 0px rgb(0, 0, 0, 0.4);
    padding: 10px;
    font-size: 1rem;
    border-radius: 2px;
    font-weight: 400;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1040px) {
      flex-direction: column;
      justify-content: flex-start;
      gap: 20px;
    }

    .topTitle {
      display: flex;
      justify-content: left;
      align-items: center;

      div {
        padding: 5px 5px 0 10px;
      }

      span {
        font-weight: 800;
        padding: 5px 5px 0 5px;
      }
    }

    .topNav {
      // border: 1px solid red;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      gap: 30px;

      @media (max-width: 1040px) {
        flex-direction: row-reverse;
        justify-content: space-between;
      }
      .topNav-close {
        display: flex;
        color: #000000;

        &:hover {
          color: #07659a;
        }

        &:active {
          color: darken(#07659a, 10%);
        }
      }
    }
  }

  .WarningMessages-paper {
    // border: 1px red solid;
    padding: 20px;
    height: 100%;
    background-color: transparent;

    .gridContainer {
      // border: 1px solid red;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0;
      margin-top: 20px;

      .gridItem {
        // border: 1px solid rgb(204, 13, 147);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        margin: 20px 0;

        .gridItem-formControl {
          // border: 1px solid rgb(0, 21, 255);
          width: 100%;
          margin: 0;

          .sliderLabel {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .sliderInput {
              display: flex;
            }
          }
        }
      }

      .gridItem-composite {
        // border: 1px solid red;

        .gridItem-formControl-composite {
          // border: 1px solid rgb(0, 21, 255);
          padding: 0;
          margin: 0;

          .gridItem-formControlLabel {
            // border: 1px solid rgb(81, 255, 0);
            margin-left: 0;
            margin-right: 0;

            display: flex;
            align-items: center;

            &.left {
              margin-left: -12px;
            }
            // &.right {
            //   // justify-content: flex-start;
            //   // margin-right: -16px;
            //   // flex-direction: row-reverse;
            // }
            .frequency-label {
              margin-right: 20px;
            }

            //       .version-label {
            //         padding: 0;
            //         margin: 0;
            //         // // white-space: nowrap;
            //         // // overflow: hidden;
            //         // // text-overflow: ellipsis;
          }
        }
      }
    }
    .gridError {
      margin: 0;
      padding: 0;
    }

    hr {
      height: 1.5px;
      width: 100%;
      background-color: #07659a;
      opacity: 0.5;
      border: none;
      margin: 10px auto 10px auto;
      // transition: all 0.7s ease-in-out;
      transition: opacity 0.7s ease-in-out, width 0.7s ease-in-out;
      // Transition douce pour l'opacité et la largeur
      &:hover {
        opacity: 0.2;
        width: 80%;
      }
    }

    .footerWarningMessages {
      // border: 1px red solid;
      margin: 40px 0px 20px 0px;
      width: 100%;
      display: flex;
      justify-content: space-around;
    }
  }
}
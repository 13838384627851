.ResetPassWord {
  form {
    background-color: white;
    padding: 0px 60px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .ResetPassWordHeader {
      // border: 1px red solid;
      margin: 30px 0 20px 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .ResetPassWordHeader__logo {
        display: flex;
        width: 80px;
        height: auto;

        img {
          width: 100%;
        }
      }
      .titles {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 20px;

        .ResetPassWordHeader__title {
          color: #07659a;
          text-align: center;
          font-size: 2rem;
        }
        .ResetPassWordHeader__subtitle {
          color: #364148;
          text-align: center;
          font-size: 1rem;
        }
      }
    }

    // .inputPW:not(.last) {
    .inputPW {
      margin-bottom: 10px;
    }

    // .inputPW-label {
    //   font-size: 14px;
    //   font-weight: bold;
    //   color: #07659a;
    //   margin-top: 7px;
    // }

    //!----------------------------------------------------------------------------------
    //!----------------------------------------------------------------------------------

    .inputField {
      // border: 1px red solid;
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      width: 370px;
      margin: 10px 0 10px 0;

      .passwordMessage {
        font-size: 12px;
        padding-bottom: 7px;

        &.Faible {
          color: red; /* Couleur pour les mots de passe faibles */
        }

        &.Moyen {
          color: orange; /* Couleur pour les mots de passe de force moyenne */
        }

        &.Eleve {
          color: green; /* Couleur pour les mots de passe forts */
        }
      }

      .inputField-label {
        font-size: 14px;
        font-weight: bold;
        color: #07659a;
        margin-bottom: 7px;
      }

      .inputField-NewPW {
        // border: 1px rgb(0, 255, 55) solid;
        display: flex;
        justify-content: center;

        .MuiInputBase-adornedEnd {
          // border: 1px rgb(0, 255, 55) solid;
          display: flex;

          padding: 0;
          margin: 0;
        }
        // align-items: center;

        .MuiInputBase-input {
          // border: 1px solid red;
          margin: 0;
          // padding: 0;
        }

        // .visibilityIcon {
          // border: 1px rgb(0, 255, 55) solid;
        // }
        // height: 52px;
        // padding: 10px;
        // margin: 2px 0 2px 0;
        // border-radius: 5px;
        // border: solid grey 1px;

        // &:invalid[focused="true"] {
        //   border: 1px solid red;
        // }

        // &:invalid[focused="true"] ~ span {
        //   display: block;
        // }
      }

      span {
        font-size: 12px;
        padding-bottom: 7px;
        color: red;
        display: none;
      }
    }

    .submitButton {
      width: 100%;
      height: 40px;
      border-radius: 5px;
      border: none;
      background-color: #07659a;
      color: white;
      margin: 20px;
      font-size: large;
      font-weight: bold;
      cursor: pointer;

      &:hover {
        background-color: #0380c9d5;
      }

      &.nope {
        &:hover {
          animation: nope 0.4s forwards;
        }

        @keyframes nope {
          0% {
            transform: translateX(0px);
          }
          20% {
            transform: translateX(-8px);
          }
          40% {
            transform: translateX(8px);
          }
          60% {
            transform: translateX(-8px);
          }
          80% {
            transform: translateX(8px);
          }
          100% {
            transform: translateX(0px);
          }
        }
      }
    }

    .errorMessage {
      display: flex;
      margin-bottom: 20px;
      width: 400px;
      justify-content: center;
      color: red;
      font-weight: 500;
    }
  }
}

.Utilisateurs {
  // border: 1px solid rgb(52, 177, 150);
  display: flex;
  flex-direction: column;
  width: 95%;
  height: 90vh;
  position: relative;
  top: 30px;

  .topPage {
    box-shadow: 1px 1px 5px 0px rgb(0, 0, 0, 0.4);
    padding: 10px;
    font-size: 1rem;
    border-radius: 2px;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: flex-start;
      gap: 20px;
    }

    .topTitle {
      display: flex;
      justify-content: left;
      align-items: center;

      div {
        padding: 5px 5px 0 10px;
      }

      span {
        font-weight: 800;
        padding: 5px 5px 0 5px;
      }
    }

    .topNav {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      gap: 30px;

      @media (max-width: 1040px) {
        flex-direction: row-reverse;
        justify-content: space-between;
      }

      .topNav-close {
        display: flex;
        justify-content: center;
        color: #000000;

        &:hover {
          color: #07659a;
        }

        &:active {
          color: darken(#07659a, 10%);
        }
      }
    }
  }

  .css-13xfq8m-MuiTabPanel-root {
    padding: 0px;
  }

}


.Cards {
  display: flex;
  gap: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  @media (max-width: 1360px) {
    flex-wrap: wrap;
  }

  & .parentContainer {
    width: 100%;
  }
}
.GridCards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  gap: 20px;
}
/* Media queries for responsive layout */
@media (max-width: 1040px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.editOneUserOfAll {
  // border: 2px solid rgb(52, 177, 150);
  width: 95%;
  margin-bottom: 30px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 30px;

  .topPage {
    box-shadow: 1px 1px 5px 0px rgb(0, 0, 0, 0.4);
    padding: 10px;
    font-size: 1rem;
    border-radius: 2px;
    font-weight: 400;
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: flex-start;
      gap: 20px;
    }

    .topTitle {
      display: flex;
      justify-content: left;
      align-items: center;

      div {
        padding: 5px 5px 0 10px;
      }

      span {
        font-weight: 800;
        padding: 5px 5px 0 5px;
      }
    }

    .topNav {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      gap: 30px;

      @media (max-width: 1040px) {
        flex-direction: row-reverse;
        justify-content: space-between;
      }

      .topNav-close {
        display: flex;
        justify-content: center;
        color: #000000;

        &:hover {
          color: #07659a;
        }

        &:active {
          color: darken(#07659a, 10%);
        }
      }
    }
  }

  .editOneUserOfAll-paper {
    // border: 2px solid rgb(52, 177, 150);
    padding: 20px;
    height: 100%;
    background-color: transparent;

    .editOneUserOfAll-form {
      // border: 1px solid rgb(180, 23, 242);
      display: flex;
      flex-direction: column;
      // padding: 20px;
      // overflow: auto;
      // overflow-x: hidden;
      justify-content: space-between;
      align-items: center;

      hr {
        height: 2px;
        width: 85%;
        background-color: #07659a;
        opacity: 0.5;
        border: none;
        margin: 20px auto 20px auto;
        transition: all 0.7s ease-in-out;

        &:hover {
          opacity: 0.2;
          width: 60%;
        }
      }

      .footerEditOneUserOfAll {
        width: 100%;
        display: flex;
        justify-content: space-around;
        margin: 10px;
      }
    }
  }
}

.GridTable {
  // border: 1px solid red;
  .dataGridBox {
    // border: 1px solid red;
    // height: 100%;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .grid-customToolbar-container {
    display: flex;

    justify-content: space-between;
    margin: 10px 0 10px 10px;
  }

  .btn-supprimer {
    margin: 20px;
  }

  .MuiDataGrid-columnHeaderTitle {
    font-weight: bolder !important;
  }

  .edit-icon {
    color: #305d42;

    &:hover {
      color: lighten($color: #305d42, $amount: 20%);
    }
  }
}
// .Mui-evenRow {
//   background-color: #ebd8ed;
// }
// .Mui-oddRow {
//   background-color: #afc6d7;
// }

.formInput {
  display: flex;
  flex-direction: column;
  width: 280px;

  label {
    font-size: 14px;
    font-weight: bold;
    color: #07659a;
    margin-top: 7px;
  }

  input {
    height: 12px;
    padding: 10px;
    margin: 2px 0 2px 0;
    border-radius: 5px;
    border: solid grey 1px;

    &:invalid[focused="true"] {
      border: 1px solid red;
    }

    &:invalid[focused="true"] ~ span {
      display: block;
    }
  }

  span {
    font-size: 12px;
    padding-bottom: 7px;
    color: red;
    display: none;
  }
}

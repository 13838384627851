.sidebar {
  display: flex;
  flex-direction: column;
  overflow: auto;

  .top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 14px 0 0 0;

    .logo {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 60%;
        height: auto;
      }
    }
  }

  .bonjourUser {
    padding: 0px 20px;
    font-size: 14px;
    font-weight: 600;
    color: #07659a;

    /* Limiter à une ligne avec points de suspension */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    /* Les propriétés pour gérer les retours à la ligne */
    //   word-wrap: break-word; /* Permet de couper les mots trop longs pour revenir à la ligne */
    //   white-space: normal; /* Permet aux textes de revenir à la ligne automatiquement */
    //   word-break: break-word; /* Casse le mot et force le retour à la ligne */

    /* Optionnel : définissez une largeur maximale */
    max-width: 200px; /* Ajustez la largeur selon vos besoins */
  }

  hr {
    height: 0;
    border: 0.5px solid rgb(230, 227, 227);
    width: 80%;
    align-self: center;
    margin: 12px 0 12px 0;
  }

  .center {
    display: flex;
    flex-direction: column;
    // gap: 10px;
    padding-left: 20px;

    .title {
      font-size: 16px;
      font-weight: bold;
      padding-left: 5px;
      color: #9c9a99;
    }

    .sub-title {
      font-size: 22px;
      font-weight: bold;
      padding-left: 22px;
      padding-bottom: 5px;
      color: #07659a;
      transition: transform 0.3s ease-out;
      transform-origin: left;

      &:hover {
        color: #2788c1;
        transform: scale(1.1);
      }
    }

    .menu {
      display: flex;
      flex-direction: column;

      ul {
        list-style: none;
        margin: 10px 0 0 0;
        padding: 0;
      }

      .sub-menu {
        display: flex;
        flex-direction: column;

        .parent {
          // border: 1px solid red;
          display: flex;
          align-items: center;
          background-color: #d8d5ee;
          justify-content: space-between;

          .iconAndText {
            display: flex;
            align-items: flex-end;
          }

          .arrow {
            // position: absolute;
            right: 10px;
          }

          span {
            display: block;
            width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .close {
          opacity: 0;
          max-height: 0px;
          visibility: hidden;
          // display: none;
          transition: all 100ms ease;
        }
        .open {
          // display: block;
          opacity: 1;
          visibility: visible;
          transition: all 500ms ease;
        }

        & li {
          padding-left: 20px;

          &:nth-of-type(1) {
            margin-top: 10px;
          }
        }

        & li a {
          text-decoration: none;
          list-style: none;
        }
      }
    }
  }

  // .LandingMuiCard-text {
  //   white-space: nowrap;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   // max-width: 200px;

  //   &:hover {
  //     white-space: normal;
  //     overflow: visible;
  //     z-index: 1;
  //   }
  // }

  .bottom {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 20px;

    // .PROFIL {
    //   // border: 1px solid red;
    //   display: flex;
    //   flex-direction: row;
    // }

    .bottom-title {
      font-size: 15px;
      font-weight: bold;
      font-style: italic;
      // color: #07659a;

      /* Les propriétés pour gérer les retours à la ligne */
      // word-wrap: break-word; /* Permet de couper les mots trop longs pour revenir à la ligne */
      // white-space: normal; /* Permet aux textes de revenir à la ligne automatiquement */
      // word-break: break-word; /* Casse le mot et force le retour à la ligne */
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    .colorOption {
      display: flex;

      div {
        width: 20px;
        height: 20px;
        border-radius: 5px;
        border: 1px solid #999999;
        cursor: pointer;
        margin: 5px;
      }

      .light {
        background-color: whitesmoke;
      }

      .dark {
        background-color: #333;
      }
    }
  }

  .menuLink {
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    transition: all 500ms ease;
    border-radius: 2px 0 0px 2px;

    &:hover {
      background-color: #d2e8f5;
    }

    &:active {
      background-color: #afdaf3;
    }

    .icon {
      font-size: 18px;
      color: #07659a;
    }

    span {
      font-size: 13px;
      font-weight: bold;
      color: #3e3e3e;
      margin-left: 10px;
    }
  }
}

.active {
  background: #afdaf3;
  padding-right: 10px;
}

// .title {
//   font-size: 10px;
//   font-weight: bold;
//   color: #07659a;
//   margin-top: 15px;
//   margin-bottom: 5px;
// }

// & li:first-child{
//   display: flex;
//   flex-direction: column;
// }

// color: #07659a;
//         text-decoration: none;
//         font-weight: bold;

//         &:hover {
//             color: #0380c9d5
//         }

.Configuration {
  display: flex;
  flex-direction: column;
  width: 95%;
  position: relative;
  top: 30px;
  height: 90vh;

  // .css-13xfq8m-MuiTabPanel-root {
  //   padding: 0px;
  // }

  .MuiBox-root {
    // border: 1px solid red;
    margin-top: 10px;

    .MuiTabPanel-root{
      padding: 0;
    } 
    // css-13xfq8m-MuiTabPanel-root
  }
}

.UserProfilesServicesForm {
  // border: 1px red solid;
  // width: 100%;
  // margin-bottom: 30px;
  // margin-top: 30px;
  // display: flex;
  // flex-direction: column;
  // justify-content: flex-start;
  // gap: 30px;
  // border: 1px red solid;
  width: 100%;
  margin: 0 !important;
  display: flex;
  // flex-direction: column;
  // justify-content: flex-start;
  flex-direction: row;
  align-items: center;

  .gridContainer {
    // border: 1px solid red;
    width: 100%;
    margin: 0 !important;
    display: flex;
    flex-direction: row;
    align-items: center;

    .gridItem {
      // border: 1px solid red;
      display: flex;
      flex-direction: column;
      // margin: 0 !important;
      padding: 20px;
      padding-left: 20px;
      margin-bottom: 30px;

      .gridItem-formControl {
        // border: 1px solid rgb(0, 21, 255);
        margin: 0;
        width: 100%;
      }

      &.checkboxItem {
        // border: 1px solid red;
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
      }

      .checkboxItem-title {
        // border: 1px solid rgb(0, 255, 55);
        // font-weight: 500;
        // color: #07659a;
        // margin: 20px 0;
        font-weight: 500 !important;
        color: #07659a;
        margin: 5px 0 0 0 !important;
      }

      .Btn-AddProfile {
        // border: 1px solid red;
        // background-color: #57526b59;
        // color: rgb(89, 91, 94);
        // font-weight: bold;
        display: flex;
        width: 100%;
      }
    }
  }
  .contact-formControl {
    border-bottom: solid 1px rgb(155, 156, 154);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    margin-bottom: -5px;
    padding: 0;
    width: 100%;

    // .contact-checkbox {
    // border: solid 1px red;
    // }

    .contact-label {
      // border: solid 1px red;
      display: flex;
    }
  }

  .allMuSelect-formControl {
    // border: solid 1px red;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    margin: 0;
    // margin-bottom: -50px;
    padding: 0;
    width: 100%;

    .allMuSelect-checkbox {
      padding-left: 0px;
    }
    // .allMuSelect-label {
    //   // display: flex;
    // }
  }
}

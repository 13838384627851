.dialogChangePassword {
  // margin: 0;
  // padding: 0;
  //   .MuiPaper-root {
  //     border: 3px rgb(51, 255, 0) solid;
  //     display: flex;
  //     flex-direction: column;
  //   }

  //   .dialogTitle {
  //     border: 1px red solid;
  //   }

  .MuiDialogContent-root {
    // border: 1px red solid;
    //   margin: 0;
    padding: 0;

    .dialogGridContainer {
    //   border: 3px rgb(0, 255, 0) solid;
      margin: 0;
      width: 100%;

      .dialogGridItem-text {
        // border: 3px rgb(55, 0, 255) solid;
        padding: 0px 20px 10px 38px;
        // margin: 0;
      }

      .dialogGridItem {
        // border: 1px rgb(55, 0, 255) solid;
        padding: 10px 0px 10px 38px;
      }

      .dialogGridItem-error {
        // border: 1px rgb(55, 0, 255) solid;
        display: flex;
        justify-content: left;
        align-items:flex-end;
        padding: 0;
        span{
            margin: 0;
            padding: 0px 0px 10px 20px;
        }
      }

      .dialogGridItem-message {
        // border: 1px rgb(55, 0, 255) solid;
        display: flex;
        justify-content: left;
        align-items:flex-end;
        padding: 0;
        span{
            margin: 0;
            padding: 20px 20px 20px 38px;
        }
      }
    }
  }

  //   .MuiDialogActions-root {
  //     border: 1px rgb(4, 0, 255) solid;
  //   }
}

.PopUpMessages {
  .PopUpMessages-paper {
    // border: 1px red solid;
    padding: 20px;
    height: 100%;
    background-color: transparent;

    .gridContainer {
      // border: 1px solid red;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0;
      margin-top: 20px;

      .gridItem {
        // border: 1px solid rgb(204, 13, 147);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        margin: 20px 0;

        .gridItem-formControl {
          // border: 1px solid rgb(0, 21, 255);
          width: 100%;
          margin: 0;

          .sliderLabel {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .sliderInput {
              display: flex;
            }
          }
          .orderBy {
            display: flex;

            .orderBy-control {
              margin-left: 20px;
            }
          }
        }
      }

      .gridItem-composite {
        // border: 1px solid red;

        .gridItem-formControl-composite {
          // border: 1px solid rgb(0, 21, 255);
          padding: 0;
          margin: 0;

          .gridItem-formControlLabel {
            // border: 1px solid rgb(81, 255, 0);
            margin-left: 0;
            margin-right: 0;

            display: flex;
            align-items: center;

            &.left {
              margin-left: -12px;
            }
            // &.right {
            //   // justify-content: flex-start;
            //   // margin-right: -16px;
            //   // flex-direction: row-reverse;
            // }
            .frequency-label {
              margin-right: 20px;
            }

            //       .version-label {
            //         padding: 0;
            //         margin: 0;
            //         // // white-space: nowrap;
            //         // // overflow: hidden;
            //         // // text-overflow: ellipsis;
          }
        }
      }
    }
    .gridError {
      margin: 0;
      padding: 0;
    }

    hr {
      height: 1.5px;
      width: 100%;
      background-color: #07659a;
      opacity: 0.5;
      border: none;
      margin: 10px auto 10px auto;
      // transition: all 0.7s ease-in-out;
      transition: opacity 0.7s ease-in-out, width 0.7s ease-in-out;
      // Transition douce pour l'opacité et la largeur
      &:hover {
        opacity: 0.2;
        width: 80%;
      }
    }

    .footerPopUpgMessages {
      // border: 1px red solid;
      margin: 40px 0px 20px 0px;
      width: 100%;
      display: flex;
      justify-content: space-around;
    }
  }
}

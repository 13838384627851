.LandingMuiCard-root {
  border: 1px rgb(208, 208, 226) solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .LandingMuiCard-imgContainer {
    // border: 1px solid red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 0px;
    height: 50px;


    .LandingMuiCard-logo {
      max-height: 40px;
      object-fit: contain;
    }
  }

  .LandingMuiCard-cardContent {
    // border: 1px solid red;
    display: flex;
    flex-direction: column;
    padding: 10px 30px 20px 30px;

    &::after {
      content: "";
      width: 100%;
      height: 10px;
      background-color: rgb(143, 143, 201);
      position: absolute;
      bottom: 0;
      left: 0;
      border-radius: 0 0 0.5px 0.5px;
    }

    .LandingMuiCard-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      // max-width: 200px;

      &:hover {
        white-space: normal;
        overflow: visible;
        z-index: 1;
      }
    }
  }
}
.menuOpenIcon {
  position: absolute;
  top: 1.2vh; /* 1.5% de la hauteur de l'écran */
  left: 15px;
  z-index: 2;
  color: #07659a;
  cursor: pointer;

  &:hover {
    animation: ring 0.4s forwards;
  }

  @keyframes ring {
    0% {
      transform: translateX(0px);
    }
    20% {
      transform: translateX(-3px);
    }
    40% {
      transform: translateX(3px);
    }
    60% {
      transform: translateX(-3px);
    }
    80% {
      transform: translateX(3px);
    }
    100% {
      transform: translateX(0px);
    }
  }
}

.Dashboard {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  .leftSide {
    // border: red 2px dashed;
    z-index: 1;
    position: fixed;
    width: 230px;
    left: 10px;
    display: flex;
    flex-direction: column;
    height: 98vh;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 0.25rem;
    animation: apparition 0.55s ease-out;

    @keyframes apparition {
      from {
        opacity: 0;
        transform: translateX(-100px);
      }
      to {
        opacity: 1;
        transform: translateX(0px);
      }
    }

    .sidebar {
      overflow-y: scroll;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .mainSide {
    // border: red 2px dashed;
    height: 98vh;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    overflow: auto;
    // transition: width 0.5s ease-out; /* Ajout de la transition sur la propriété width */

    &.mainSideFullScreen {
      position: fixed;
      right: 10px;
      width: calc(100% - 20px);

      animation: fullScreen 0.5s ease-out;

      @keyframes fullScreen {
        from {
          opacity: 0;
          right: 10px;
          width: calc(100% - 260px);
        }
        to {
          opacity: 1;
          right: 10px;
          width: calc(100% - 20px);
        }
      }
    }

    &.mainSideReducedScreen {
      position: fixed;
      right: 10px;
      width: calc(100% - 260px);

      animation: reducedScreen 0.5s ease-out; /* Ajout de l'animation pour la transition */

      @keyframes reducedScreen {
        from {
          opacity: 0;
          right: 10px;
          width: calc(100% - 20px);
        }
        to {
          opacity: 1;
          right: 10px;
          width: calc(100% - 260px);
        }
      }

      .sidebar {
        overflow-y: scroll;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
}

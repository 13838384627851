.muEditUser-main-wrapper {
    // border: 2px solid rgb(52, 177, 150);
    width: 95%;
    margin-bottom: 30px;
    margin-top: 30px;
    
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 30px;
}